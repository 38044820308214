.createNft{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 700px;
    background-image: url("../../assets/images/backgradient.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 150px;
    width: 100%;
}
.createNft-top{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}
.createNft-bottom{
    width: 50%;
    height: 100px;
}
.left-arrow{
    height: 100px;
    width: 100px;
    display: flex;
    flex-basis: 10%;
    cursor: pointer;
}
.right-arrow{
    height: 100px;
    width: 100px;
    display: flex;
    flex-basis: 10%;
    cursor: pointer;
}
.createbase{
    padding: 20px;
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/createBase.svg");
    background-repeat: no-repeat;
    width: 80%;
    height: 500px;
    background-size: 100% 100%;
}
.create-btn{
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Philosopher;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    cursor: pointer;
    position: relative;
    background-image: url("../../assets/images/createbtn1.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 100%;
}
.create-btn > h1{
    position: relative;;
}
.create-comingsoon{
    position: absolute;
    top:5px;
    right: -75px;
    color: #00FFAA;
    font-size: 14px;
    font-weight: 600;
    line-height: 12px;
    /* width: 40px; */
    word-wrap: break-word;
}
.createtoon{
    height: 300px;
    width: 250px;
}
.arrows{
    position: relative;
}
.arrowIcon{
    position: absolute;
    left: 33px;
    top: 33px;
    cursor: pointer;
}
@media(max-width:1200px){
    .createtoon{
        height: 150px;
        width: 200px;
    }
}
@media(max-width:940px){
    .createtoon{
        height: 220px;
        width: 300px;
    }
    .create-btn > h1{
        font-size: 22px;
    }
    .create-btn > h1 > p{
        font-size: 12px;
        right: -65px;
    }
    .createbase{
        flex-basis: 80%;
    }
}
@media(max-width:580px){
    .createtoon{
        height: 170px;
        width: 170px;
        padding: 10px 20px;
    }
    .right-arrow{
        height: 50px;
        width: 50px;
    }
    .left-arrow{
        height: 50px;
        width: 50px;
    }
    .createNft{
        padding-top: 0px;
        height: 600px;
    }
    .createbase{
        height: 350px;
        padding: 0 20px;
        width: 100%;
        flex-basis: unset;
    }
    .arrowIcon{
        left: 15px;
        top: 15px;
        width: 20px;
    }
    .createNft-bottom{
        height: 100px !important;
        width: 80% !important;
    }
    .create-btn{
        height: 100% !important;
        width: 100% !important;
        font-size: 16px;
    }
}