.mobile-title{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 47px;
    color: #FFFFFF;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
}
.mobile-text{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #FFF5ED;
    width: 90%;
}
.mobile-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 750px;
    background-image: url("../../assets/images/backgradient.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 0px 50px;
}
.mobile-layer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/mobilemain.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 40px 80px;
    z-index: 10;
}
.mobile-banner-left{
    display: flex;
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
    margin-bottom: 100px;
}
.mobile-banner-right{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 50%;
    padding-top: 150px;
    margin-bottom: 100px;
}
.round2{
    position: absolute;
    width: 250px;
    height: 250px;
    left: 220px;
    top: 600px;
    z-index: 1;
    background: rgba(196, 196, 196, 0.01);
    opacity: 0.8;
    box-shadow: inset 0px 124.364px 178.574px -114.798px rgba(255, 255, 255, 0.5), inset 0px 22.3218px 35.0771px -12.7553px #FFFFFF, inset 0px -261.484px 216.84px -204.085px rgba(96, 68, 145, 0.3), inset 0px 312.505px 318.883px -153.064px rgba(202, 172, 255, 0.3), inset 0px 12.7553px 57.3989px rgba(154, 146, 210, 0.3), inset 0px 3.18883px 127.553px rgba(227, 222, 255, 0.2);
    backdrop-filter: blur(318.883px);
    border-radius: 382.659px;
}

.round4{
    position: absolute;
    width: 60px;
    height: 60px;
    right: 200px;
    top: 100px;
    z-index: 1;
    background: rgba(196, 196, 196, 0.01);
    opacity: 0.8;
    box-shadow: inset 0px 124.364px 178.574px -114.798px rgba(255, 255, 255, 0.5), inset 0px 22.3218px 35.0771px -12.7553px #FFFFFF, inset 0px -261.484px 216.84px -204.085px rgba(96, 68, 145, 0.3), inset 0px 312.505px 318.883px -153.064px rgba(202, 172, 255, 0.3), inset 0px 12.7553px 57.3989px rgba(154, 146, 210, 0.3), inset 0px 3.18883px 127.553px rgba(227, 222, 255, 0.2);
    backdrop-filter: blur(318.883px);
    border-radius: 382.659px;
}
.round3{
    position: absolute;
    width: 80px;
    height: 80px;
    right: 100px;
    top: 150px;
    z-index: 1;
    background: rgba(196, 196, 196, 0.01);
    opacity: 0.8;
    box-shadow: inset 0px 124.364px 178.574px -114.798px rgba(255, 255, 255, 0.5), inset 0px 22.3218px 35.0771px -12.7553px #FFFFFF, inset 0px -261.484px 216.84px -204.085px rgba(96, 68, 145, 0.3), inset 0px 312.505px 318.883px -153.064px rgba(202, 172, 255, 0.3), inset 0px 12.7553px 57.3989px rgba(154, 146, 210, 0.3), inset 0px 3.18883px 127.553px rgba(227, 222, 255, 0.2);
    backdrop-filter: blur(318.883px);
    border-radius: 382.659px;
}
.round5{
    position: absolute;
    width: 150px;
    height: 150px;
    left: 50px;
    top: 50px;
    z-index: 1;
    background: rgba(196, 196, 196, 0.01);
    opacity: 0.8;
    box-shadow: inset 0px 124.364px 178.574px -114.798px rgba(255, 255, 255, 0.5), inset 0px 22.3218px 35.0771px -12.7553px #FFFFFF, inset 0px -261.484px 216.84px -204.085px rgba(96, 68, 145, 0.3), inset 0px 312.505px 318.883px -153.064px rgba(202, 172, 255, 0.3), inset 0px 12.7553px 57.3989px rgba(154, 146, 210, 0.3), inset 0px 3.18883px 127.553px rgba(227, 222, 255, 0.2);
    backdrop-filter: blur(318.883px);
    border-radius: 382.659px;
}
.character1{
    position: absolute;
    left: 500px;
    top: 200px;
    z-index: 1;
}

@media(max-width:940px){
    .mobile-banner{
        flex-direction: column;
    }
    .mobile-banner-right{
        padding-top: 0 !important;
    }
    .round2 {
        width: 100px;
        height: 100px;
        left: 20px;
        border-radius: 382.659px;
    }
    .round4 {
        width: 40px;
        height: 40px;
        right: 100px;
        top: 70px;
    }
    .round3 {
        width: 60px;
        height: 60px;
        right: 50px;
        top: 100px;
    }
    .round5{
        width: 100px;
        height: 100px;
        left: 50px;
        top: 50px;
        z-index: 0.1;
    }
    .mobile-layer{
        height: 200px;
    }
    .mobile-layer > img{
        height: 180px;
    }
    .character1 {
        left: 350px;
        top: 120px;
        height: 100px !important;
    }
}
@media(max-width:580px){
    .mobile-layer{
        margin-top: 150px;
        padding: 40px 20px;
    }
    .mobile-inner-layer{
        height: 100px;
        width: 300px;
    }
    .character1{
        height: 70px;
        width: 100px;
        left: 350px;
    }
    .mobile-banner{
        height: auto;
    }
    .character1{
        left: 200px;
        top: 150px;
    }
    .mobile-layer > img{
        height: 120px;
        width: 200px;
    }
    .mobile-banner-left{
        padding-top: 0px;
        margin-bottom: 0px;
        flex-basis: unset !important;
        width: 90% !important;
    }
    .mobile-banner-right{
        padding-top: 0px !important;
        margin-bottom: 0px;
        flex-basis: unset !important;
    }
}