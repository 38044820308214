.teammember-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 900px;
    background-image: url("../../assets/images/backgradient2.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}



.team-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 500px !important;
    margin-bottom: 90px;
    flex-basis: 80%;
}

.teams-card-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    transition: transform 450ms;
}
.teams-card-div:hover{
    transform: scale(1.08);
}
.team-header{
    background-image: url("../../assets/images/teamNamePlate.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 40px;
}
.team-header  > p {
    Font-family: Philosopher;
    font-style: Bold;
    font-size: 18px;
    line-height: 20px;
    text-align: Center;
    color: #88461F;
}
.teammember{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}
.teammember-details{
    font-family: Philosopher;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    width: 250px;
    margin-top: 10px;
    height: 250px !important;
}
.team-title{
    font-family: Philosopher;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #591B6D;
    margin-bottom: -50px;
}
.team-picture{
    position: relative;
    height: 250px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.team-picture > img {
    object-fit: contain;
}
@media(max-width: 1100px){
    .team-title{
        margin-bottom: 0px;
    }
    .team-div{
        margin-top: 100px !important;
        flex-basis: 100%;
    }
    .teams-card-div{
        margin: 0px 70px;
    }
    .teammember-div{
        height: 1600px !important;
        padding-top: 100px;
    }
    .teammember-details{
        height: 220px !important;
    }
}
@media(max-width:840px){
    .teammember-div{
        height: 2000px !important;
    }
}
@media(max-width:580px){
    .teammember-div{
        height: 2000px !important;
        padding-top: 0px;
    }
    .team-title{
        margin-top: 0px;
    }
    .teams-card-div{
        margin: 0px;
        margin-top: 50px;
    }
    .team-div{
        margin-top: 10px !important;
        flex-basis: 100%;
    }
}
/*------- inverstors --------*/

.investors{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 750px;
    background-image: url("../../assets/images/backgradient.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.investors-title{
    font-family: Philosopher;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #591B6D;
    margin-bottom: -50px;
}
.investors-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 300px !important;
    flex-basis: 80%;
    margin: 100px 100px;
}
.logo-cover{
    height: 160px;
    width: 160px;
    margin: 1px 10px;
    object-fit: inherit;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #591B6D;
    display: flex;
    align-items: center;
    justify-content: center;
}
.x21-img{
    height: 120px;
    width: 120px;
    object-fit: inherit;
    border-radius: 10px;
    padding: 5px;
}
.investor-img{
    height: 160px;
    width: 160px;
    object-fit: inherit;
    border-radius: 10px;
    padding: 5px;
}
.investor-img1{
    min-height: 100px;
    width: 160px;
    object-fit: inherit;
    border-radius: 10px;
    padding: 30px 5px;
}
.investor-img2{
    min-height: 120px;
    width: 180px;
    object-fit: inherit;
    border-radius: 10px;
    padding: 30px 5px;
}
.magnus-img{
    height: 220px;
    width: 260px;
    object-fit: cover;
    padding: 5px;
}
@media(max-width: 1100px){
    .investors-title{
        margin-bottom: 0px;
    }
    .investors-div{
        margin: unset;
        margin-top: 10px !important;
        flex-basis: 100%;
    }
    .investors{
        height: 800px !important;
        padding-top: 100px;
    }
    .investor-img{
        margin: 1px 40px !important;
    }
}
@media(max-width:840px){
    .investors{
        height: auto !important;
    }
    .investors-div{
        margin-top: 50px !important;
    }
    .logo-cover{
        margin-top: 10px;
    }
}
@media(max-width:580px){
    .investors{
        height: auto !important;
        padding-top: 50px;
    }
    .investors-title{
        margin-top: 0px;
    }
    .investors-div{
        flex-basis: 100%;
    }
    .investor-img{
        height: 140px;
        width: 180px;
        margin: 10px 10px !important;
    }
    .logo-cover{
        height: 160px;
        width: 160px;
    }
    .x21-img{
        height: 100px;
        width: 100px;
        object-fit: inherit;
        border-radius: 10px;
        padding: 5px;
    }
    .investor-img{
        height: 160px;
        width: 160px;
        object-fit: inherit;
        border-radius: 10px;
        padding: 5px;
    }
    .investor-img1{
        min-height: 120px;
        width: 150px;
        object-fit: inherit;
        border-radius: 10px;
        padding: 30px 5px;
    }
    .magnus-img{
        height: 240px;
        width: 280px;
        object-fit: cover;
        padding: 5px;
    }
}