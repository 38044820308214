@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

/* .nav-item{
    font-family: Staatliches;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.nav-item > li {
    margin: 15px 50px 0px 0px;
    list-style-type: none;
    backdrop-filter: blur(2px) !important;
    position: relative;
}
a{
    text-decoration: none;
    color: #fff;
}
.navbar{
    height: 60px;
    background-color: #342e4c ;
    color:#fff;
    z-index: 100;
} */
.navbar{
    z-index: 100;
}
.play-btn{
    background-image: url("../../assets/images/playbtn.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 13px;
    width: 100px;
}
.comingsoon{
    position: absolute;
    top:52px;
    right: -8px;
    color: #00FFAA;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    width: 40px;
    word-wrap: break-word;
}
.play-btn .comingsoon{
    top: 23px;
    left: 65px;
} 

*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
  }
  body{
    font-family: Staatliches;
  }
  nav{
    background: #342e4c;
    height: 80px;
    width: 100%;
    display: flex;
  }
  label.logo{
    color: white;
    font-size: 35px;
    line-height: 80px;
    padding: 0 100px;
    font-weight: bold;
    flex-basis: 30%;
  }
  nav ul{
    float: right;
    margin-right: 20px;
    font-family: Staatliches;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-decoration: none;
    flex-basis: 70%;
  }
  nav ul li{
    display: inline-block;
    line-height: 80px;
    position: relative;
    color: #fff;
    margin: 5px 50px 0px 0px;
  }
  nav ul li a{
    color: white;
    padding: 7px 13px;
    border-radius: 3px;
    text-transform: uppercase;
    text-decoration: none;
  }
  /* a.active,a:hover{
    background: #342e4c;
    transition: .5s;
  } */
  .checkbtn{
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    margin-right: 40px !important;
    cursor: pointer;
    display: none;
  }
  #check{
    display: none;
  }
  @media (max-width: 952px){
    .comingsoon{
        position: absolute;
        top:50px !important;
        right: -10px;
    }
    label.logo{
      font-size: 30px;
      padding-left: 50px;
      color: #fff;
    }
  }
  @media (max-width: 858px){
    .checkbtn{
      display: block;
      position: fixed;
      top: 1px;
      right: 1px;
    }
    ul{
      position: fixed;
      width: 100%;
      background: #342e4c;
      top: 80px;
      left: 100%;
      text-align: center;
      transition: all .5s;
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    nav ul li{
      display: block;
      margin: 20px 0;
      line-height: 30px;
    }
    a:hover,a.active{
      background: none;
      color: #0082e6;
    }
    #check:checked ~ ul{
      left: 0;
    }
    
    label.logo{
        padding-left: 20px;
    }
    nav ul{
      justify-content: center;
      align-items: center;
    }
  }

@media (max-width: 580px){
  .comingsoon{
    position: absolute;
    top:25px !important;
    right: -5px;
  } 
}