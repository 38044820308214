

.build-squad-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 700px;
    background-image: url("../../assets/images/backgradient2.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}  
.build-squad-left{
    flex-basis: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.build-squad-right{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 60%;
}
.build-canvas{
    background-image: url("../../assets/images/build-canvas.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    height: 500px;
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.build-text{
    padding: auto;
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #9D2D1B;
    width: 42%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.build-title{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 47px;
    color: #B42D17;
    width: 100%;
    margin-top: 50px;
    margin-bottom: -50px;
    margin-left: -50px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.left-toon{
    height: 410px;
    width: 302px;
}
.util-title{
    font-family: Philosopher;
    font-weight: 800 !important;
    font-size: 30px;
    line-height: 27px;
    text-align: center;
    color: #B42D17;
    text-decoration: underline;
    margin-bottom: 50px;
    margin-left: -100px;
}
.round1{
    position: absolute;
    width: 170px;
    height: 170px;
    left: 100px;
    top: 650px;
    z-index: 1;
    background: rgba(196, 196, 196, 0.01);
    opacity: 0.8;
    box-shadow: inset 0px 124.364px 178.574px -114.798px rgba(255, 255, 255, 0.5), inset 0px 22.3218px 35.0771px -12.7553px #FFFFFF, inset 0px -261.484px 216.84px -204.085px rgba(96, 68, 145, 0.3), inset 0px 312.505px 318.883px -153.064px rgba(202, 172, 255, 0.3), inset 0px 12.7553px 57.3989px rgba(154, 146, 210, 0.3), inset 0px 3.18883px 127.553px rgba(227, 222, 255, 0.2);
    backdrop-filter: blur(318.883px);
    border-radius: 382.659px;
}
.util-div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    width: 300px;
}
.utils {
    list-style-type: circle;
}
.list-item-div{
    display: flex;
    flex-direction: column !important;
    margin-left: -10px;
}
.list-item{
    padding: 5px 10px;
    font-family: Philosopher;
    font-style: normal;
    font-size: 24px;
    color: #9D2D1B;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column !important;
    margin-left: -50px;
}
.arrows1{
    position: relative;
}
    
.left-arrow1{
    position: absolute;
    height: 100px;
    width: 100px;
    display: flex;
    cursor: pointer;
    left: -190px;
    top: -50px;
}
.right-arrow1{
    position: absolute;
    height: 100px;
    width: 100px;
    display: flex;
    cursor: pointer;
    right: -130px;
    top: -50px;
}
.arrowIcon1{
    position: absolute;
    left: -160px;
    top: -15px;
    cursor: pointer;
}
.arrowIcon2{
    position: absolute;
    left: 63px;
    top: -15px;
    cursor: pointer;
}
@media(max-width: 940px){
    .left-toon{
        height: 270px !important;
        width: 250px !important;
    }
}
@media(max-width: 880px){
    .build-squad-left{
       display: none;     
    }
    .left-toon{
        height: 200px;
        width: 200px;
    }
    .build-squad-right{
        flex-basis: 80%;
    }
}
@media(max-width: 720px){
    .build-squad-left{
        display: none;  
    }
    .build-squad-right{
        flex-basis: 100%;
    }
    .build-text{
        width: 35%;
        font-size: 16px;
        line-height: 22px;
    }
    .build-title{
        font-size: 30px;
        margin-bottom: -60px;
        width: 100%;
    }
    .build-canvas{
        height: 400px !important;
    }
    .list-item-div{
        margin-left: 20px;
    }
}

@media(max-width: 580px){
    .build-text{
        width: 50%;
    }
    .build-title{
        margin-left: unset;
    }
    .build-squad-banner{
        height: 600px !important;
    }  
    .build-canvas{
        height: 400px !important;
        width: 100%;
        margin-top: 50px;
    }
    .list-item-div{
        margin-left: -10px;
    }
    .list-item{
        font-size: 20px;
        margin-left: 0px;
    }
    .util-div{
        padding: 40px;
        width: 350px !important;
        justify-content: flex-start;
    }
    .util-title{
        font-size: 20px;
        margin-left: -50px;
        margin-bottom: 10px;
    }
    .left-arrow1{
        height: 60px;
        width: 60px;
        left: -40px;
        top: -40px;
    }
    .right-arrow1{
        height: 60px;
        width: 60px;
        right: -10px;
        top: -40px;
    }
    .arrowIcon1{
        height: 20px;
        width: 20px;
        left: -25px;
        top: -20px;
    }
    .arrowIcon2{
        height: 20px;
        width: 20px;
        left: -30px;
        top: -20px;
    }
}