@import url('https://fonts.googleapis.com/css2?family=Philosopher&display=swap');
*{
    margin: 0; 
    padding: 0;   
}
.announcement{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #DC859F;
    height: 50px;
    font-size: 20px;
}
.link-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 10rem;
}
.link-btns{
    border: 4px solid #8b7d82;
    border-radius: 10px;
    padding: 5px;
    margin: 10px;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.linkimg{
    height: 100px;
    width: 100px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 5px;
    background-color: #f5f4f4;
}
.bsclink{
    color: #fff;
    margin: 5px 10px;
    text-decoration: underline;
}
.mexlink{
    color: #fff;
    margin: 5px 10px;
    text-decoration: underline;
}
.banner{
    color:#fff;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url('../../assets/images/backgroundbanner.svg');
    width: 100% !important;
    background-size: cover !important;
    position: relative;
    height: 700px;
}
.bannerbase{
    background-image: url("../../assets/images/bannerbase.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -100px;
    height: 200px;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-hero{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.buybtn{
    background-color: #9E4215;
    color: #fff;
    padding: 5px 10px;
    border: 1px solid #9E4215;
    border-radius: 5px;
    cursor: pointer;
}
.down-btn{
    margin-top: 20px;
    cursor: pointer;
}
.banner-hero > h1{
    font-family: Philosopher;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    width: 58%;
    margin-top: 2rem;
}
.cloud1{
    position: absolute;
    left: -80px;
    top: 0px;
    z-index: 1;
}
.cloud2{
    position: absolute;
    left: 400px;
    top: 1px;
    z-index: 0.8;
    display: none;
}
.cloud3{
    position: absolute;
    left: 200px;
    top: 250px;
    z-index: 0.8;
}
.cloud4{
    position: absolute;
    right: 1px;
    top: 150px;
    z-index: 0.8;
}
.cloud5{
    position: absolute;
    left: 10px;
    top: 570px;
    z-index: 0.8;
}
.cloud6{
    position: absolute;
    right: 10px;
    top: 570px;
    z-index: 0.8;
}
.baloon1{
    position: absolute;
    left: 100px;
    top: 10px;
    z-index: 0.8;
    height: 150px;
    width: 70px;
    object-fit: contain;
}
.baloon2{
    position: absolute;
    left: 250px;
    top: 70px;
    z-index: 0.8;
}
.baloon3{
    position: absolute;
    right: 200px;
    top: 10px;
    z-index: 0.8;
}
@media(max-width: 580px){
    .baloon1{
        top: 100px;
    }
    .baloon2{
        display: none;
    }
    .baloon3{
        top:20px;
        right: 100px;
        display: none;
    }
    .banner-hero > h1{
        margin-top: 2rem;
        font-size: 30px;
    }
    .cloud3{
        display: none;
    }
    .cloud5{
        display: none;
    }
    .banner{
        height: 600px !important;
    }
    .bannerbase{
        bottom: -100px !important;
    }
    .cloud6{
        top: 470px;
    }
    .cloud2{
        display: none;
    }
    .cloud4{
        right: 1px;
    }
    .ann-span{
        flex-direction: column;
        display: flex;
        align-items: center;
    }
    .announcement{
        height: 100px;
        font-size: 14px;
        word-wrap: break-word;
    }
    .link-btns{
        border: 4px solid #8b7d82;
        border-radius: 5px;
        padding: 2px;
        margin: 5px;
        width: 150px;
        height: 150px;
    }
    .linkimg{
        height: 70px;
        width: 70px;
        border-radius: 50%;
        margin: 2px;
    }
    .bsclink{
        color: #fff;
        margin: 2px 5px;
        text-decoration: underline;
    }
}