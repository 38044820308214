.roadmap{
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
    padding-top: 100px;
    height: 800px;
    background-image: url("../../assets/images/backgradient.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.roadmap-card-text{
    font-family: Philosopher;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #9E4215;
    padding: 10px;
    height: 80px;
}
.earn-title{
    font-family: Philosopher;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #591B6D;
}
.roadmap-div{
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap; */
    height: 500px;
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto !important;
    /* margin-bottom: 90px;
    flex-basis: 80%; */
}
.roadmap-div::-webkit-scrollbar{
    width: 0;
}
.roadmap-card{
    background-image: url("../../assets/images/roadmapCardBG.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 300px;
    line-height: 320px;
    width: 220px;
    min-width: 220px;
    margin: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 450ms;
}
.roadmap-card:hover{
    transform: scale(1.08);
}
.card-stick{
    position: absolute;
    top: 150px;
    left: 10px;
}
.roadmap-img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.bottom-jewel-div{
    display: flex;
    justify-content: center;
    height: 80px;
}
.roadmapcard-desc{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
}
.roadmap-card-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.roadmap-card-header{
    background-image: url("../../assets/images/roadmap-heder.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.roadmap-card-header > p {
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}
.card-img{
    height: 80px;
}
.top-jewel{
    height: 60px;
    width: 60px;
    object-fit: contain;

}
.bottom-jewel{
    height: 80px;
    width: 80px;
    object-fit: contain;
}
.down-card{
    margin-top: 20%;
}
.footer{
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-image: url("../../assets/images/footerbg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.copyright-div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-basis: 40%;
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #642889;
    padding: 10px 50px;
}
.socials{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-basis: 20%;
    z-index: 100;
}
.social-twitter > a,.social-telegram > a{
    color: #642889;
    font-size: 50px;
    height: 50px;
    width: 50px;
    padding: 10px;
}
.email-div > a{
    color: #642889;
    text-decoration: none;
    padding: 0px 5px;
}
.square-icon{
    font-size: 30px !important;
    height: 50px;
    border: 1px solid #642889;
    padding: 5px;
    border-radius: 3px;
}
.email-div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 40%;
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #642889;
    padding: 10px 50px;
}
@media(max-width:1200px){
    .down-card{
        margin-top: 0;
    }
    .roadmap{
        height: 800px !important;
    }
}
@media(max-width:580px){
    .down-card{
        margin-top: 0;
    }
    .roadmap{
        height: 700px !important;
    }
    .earn-title{
        margin-bottom: unset;
    }
    .email-div{
        padding: 10px 10px;
    }
    .roadmap-div{
        flex-basis: 80%;
        margin-bottom: unset;
        justify-content: unset !important;
    }
    .footer{
        bottom: -100px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 450px !important;
    }
    .socials{
        flex-basis: 10% !important;
    }
    .social-telegram > a{
        flex-basis: 10% !important;
    }
    .social-twitter > a{
        flex-basis: 10% !important;
    }
    .email-div{
        flex-basis: 10% !important;
    }
    .copyright-div{
        flex-basis: 10% !important;
    }

}