.play-to-earn{
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
    height: 700px;
    padding-top: 150px ;
    background-image: url("../../assets/images/backgradient2.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-bottom: 30px;
}
.reward-div{
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap; */
    max-height: 400px !important;
    display: flex;
    overflow-x: auto !important;
    align-items: center;
    justify-content: center;
}
.reward-div::-webkit-scrollbar{
    width: 0;
}
.reward-div .reward-card{
    /* min-width: 110px;
    height: 110px;
    line-height: 110px;
    text-align: center;
    background-color: #ddd; */
    margin-right: 20px;
    background-image: url("../../assets/images/rewardCard.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    width: 185px;
    min-width: 185px !important;
    margin: 20px;
    position: relative;
    transition: transform 450ms; 
}
.reward-card:hover{
    transform: scale(1.08);
}
.card-text{
    font-family: Philosopher;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 24px !important;
    letter-spacing: 0em;
    text-align: center;
    color: #9E4215;
    padding: 10px;
    padding-top: 20px !important;
    height: 80px;
}
.play-earn-title{
    font-family: Philosopher;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 0;
    color: #591B6D;
}
.card-img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.bottom-jewel-div{
    display: flex;
    justify-content: center;
    height: 80px;
}
.card-desc{
    height: 80px;
}
.card-img{
    height: 80px;
}
.top-jewel{
    height: 60px;
    width: 60px;
    object-fit: contain;
}
.bottom-jewel{
    position: absolute;
    top: 200px;
    left: 50px;
    height: 80px;
    width: 80px;
    object-fit: contain;
}
@media(max-width:1200px){
    .play-to-earn{
        height: 600px;
        padding-top: 10px;
    }
}
@media(max-width:580px){ 
    .play-to-earn{
        height: 500px;
        padding-top: unset;
    }
    .reward-div{
        justify-content: unset !important;
    }
}